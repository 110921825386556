<template>
  <div class="container-enum-multiple">
    <div class="content-enum-multiple">
      <div class="options-enum-multiple">

        <div class="option" :class="`option-multiple-${idx}`" v-for="(option, idx) in question.options" :key="idx" @click="valueChanged(option.label, idx)">
          <i class="material-icons unchecked" :class="`uncheck-${idx}`">check_box_outline_blank</i>
          <i class="material-icons checked" :class="`check-${idx}`" style="display: none">check_box</i>
          <p v-html="replaceMeta(option.label)"></p>
        </div>
        <div class="option-other" v-if="question.ui === 'multipleOther'">
          <div class="content-other" @click="other()">
            <i class="material-icons unchecked-other" v-if="!isOther">check_box_outline_blank</i>
            <i class="material-icons checked-other" v-if="isOther">check_box</i>
            <p v-html="replaceMeta(question.otherLabel)"></p>
          </div>
          <div class="option-text" v-if="isOther">
            <input class="input-other" type="text" v-model="text" :placeholder="question.placeholder" @keyup="textValue()">
            <p class="message-length-other" v-if="question.required && text.length <= 0">{{ $t('other_required') }}</p>
          </div>
        </div>
        <div class="max-choice" v-if="question.limitAnswer">
          <p class="limit-choice">{{ $t('multiple_answer_required') }} {{ question.minLength }} {{ $t('multiple_and') }} {{ question.maxLength }} {{ $t('multiple_options') }}.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceMetadata } from '../../_helpers/metadata'

export default {
  name: 'appmultiple',
  props: ['question', 'colors'],
  data () {
    return {
      send: null,
      isLimit: false,
      requireFillField: false,
      isOther: false,
      totalLimitAnswer: 0,
      text: '',
      options: []
    }
  },
  created () {
    this.options = [...new Set(this.question.options)]
    this.options.map(function (item) {
      item.disabled = false
    })
    if (this.question.randomOptions) {
      this.options = this.shuffle(this.options)
    }
  },
  mounted () {
    this.$nextTick(() => { this.send = [] })
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit(this.question.otherLabel, this.text)
      }
    })
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    metadata () {
      let data
      if (!this.isPreview) {
        data = this.$store.getters['seedData/getSeedData']
      } else {
        data = this.$store.getters['preview/getSeedData']
      }

      const metadata = data && data.metadata ? data.metadata : {}
      return metadata
    }
  },
  methods: {
    replaceMeta (str) {
      return replaceMetadata(str, this.metadata, this.answersList)
    },
    textValue () {
      switch (this.question.required) {
        case ((this.send.length > 0 && this.text.length > 0) || (this.send.length <= 0 && this.text.length > 0) || (this.send.length > 0 && this.text.length <= 0)) && this.text !== '':
          if (this.question.limitAnswer && (this.question.maxLength === this.question.minLength) && (this.send.length < this.question.maxLength)) {
            this.$root.$emit('show-btn', false)
          } else {
            this.$root.$emit('show-btn', true)
          }
          break
        case (this.send.length === 1 && this.text.length <= 0) || (this.send.length > 0 && this.text.length <= 0):
          this.$root.$emit('show-btn', false)
          break
        default:
          this.$root.$emit('show-btn', false)
          break
      }
    },
    shuffle (o) {
      for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
      return o
    },
    valueChanged (value, idx) {
      let realValue = value
      if (this.isOther && this.send.length === this.question.maxLength && this.question.limitAnswer) {
        realValue = this.question.otherLabel
      }

      const exist = this.send.includes(realValue)

      if (!exist && !this.isLimit) {
        this.send.push(value)
        const optionSelected = document.querySelector(`.option-multiple-${idx}`)
        optionSelected.style.borderColor = this.colors.splashColor
        document.querySelector(`.uncheck-${idx}`).style.display = 'none'
        document.querySelector(`.check-${idx}`).style.display = 'flex'
      } else {
        this.isLimit = false

        this.send = this.send.filter(item => item !== value)
        const optionSelected = document.querySelector(`.option-multiple-${idx}`)
        optionSelected.style.borderColor = '#e0e0e0'
        document.querySelector(`.uncheck-${idx}`).style.display = 'flex'
        document.querySelector(`.check-${idx}`).style.display = 'none'
      }

      const hasText = this.text.length > 0
      const hasSelectedOptions = this.send.length > 0

      if ((hasSelectedOptions && !this.isOther && !this.question.limitAnswer) || (hasSelectedOptions && this.isOther && !this.question.limitAnswer && hasText)) {
        this.$root.$emit('show-btn', true)
      } else if ((this.send.length === 1 && this.isOther && !this.question.limitAnswer) || (!hasSelectedOptions && !this.isLimit)) {
        this.$root.$emit('show-btn', false)
      }

      if (this.question.limitAnswer) {
        const minRange = this.question.minLength
        const maxRange = this.question.maxLength
        this.totalLimitAnswer = this.send.length
        let passOnMinRule = true
        let passOnMaxRule = true

        if (minRange !== undefined && this.totalLimitAnswer < minRange) {
          passOnMinRule = false
          this.isLimit = false
        }

        if (maxRange !== undefined) {
          if (this.totalLimitAnswer === maxRange && this.isOther) {
            this.isLimit = true
            if (hasText) {
              this.$root.$emit('show-btn', true)
            } else {
              this.$root.$emit('show-btn', false)
            }
          } else if (this.totalLimitAnswer === maxRange) {
            passOnMaxRule = true
            this.isLimit = true
          } else if (this.totalLimitAnswer > maxRange) {
            passOnMaxRule = false
            this.isLimit = true
          }
        }

        if (passOnMinRule && passOnMaxRule && this.totalLimitAnswer > 0 && (this.isOther && hasText)) {
          // this.isLimit = false
          this.$root.$emit('show-btn', true)
        } else if (passOnMinRule && passOnMaxRule && this.totalLimitAnswer > 0 && !this.isOther) {
          // this.isLimit = false
          this.$root.$emit('show-btn', true)
        } else if (this.totalLimitAnswer <= 0) {
          this.isLimit = false
          this.$root.$emit('show-btn', false)
        } else {
          this.$root.$emit('show-btn', false)
        }
      }
    },
    other () {
      const deselectOtherOption = () => {
        this.totalLimitAnswer--
        const optionSelected = document.querySelector('.option-other')
        optionSelected.style.borderColor = '#e0e0e0'
        this.text = ''
        this.send = this.send.filter(item => item !== this.question.otherLabel)
      }

      const toggleOtherOption = () => {
        if (this.isOther) {
          this.send.push(this.question.otherLabel)
          const optionSelected = document.querySelector('.option-other')
          optionSelected.style.borderColor = this.colors.splashColor
        } else {
          const optionSelected = document.querySelector('.option-other')
          optionSelected.style.borderColor = '#e0e0e0'
          this.text = ''
          this.send = this.send.filter(item => item !== this.question.otherLabel)
        }
      }

      if (this.question.limitAnswer && this.totalLimitAnswer === this.question.maxLength) {
        this.isLimit = true
        if (this.isOther) {
          this.isOther = !this.isOther
          this.isLimit = false
          deselectOtherOption()
          if (!this.isOther && this.text.length <= 0 && this.question.minLength === this.question.maxLength && this.question.limitAnswer) this.$root.$emit('show-btn', false)
          return this.isOther
        }
      } else {
        this.isOther = !this.isOther
        toggleOtherOption()

        if (!this.isOther && this.text.length <= 0 && this.question.minLength === this.question.maxLength && this.question.limitAnswer) {
          this.$root.$emit('show-btn', false)
          return
        }

        if (this.isOther && this.text.length <= 0) {
          this.$root.$emit('show-btn', false)
          return
        }

        if (!this.isOther && this.text.length <= 0 && !this.send.length > 0) {
          this.$root.$emit('show-btn', false)
          return
        }

        if (!this.isOther && this.send.length > 0) {
          this.$root.$emit('show-btn', true)
          return null
        }
      }
    },
    async submit () {
      const value = this.send
      const text = this.text
      let data = {}
      if (this.question.required) {
        if (this.isOther) {
          if (text.length > 0) {
            this.submitEvents({ value: value, isOther: true, text: text })
          } else {
            this.requireFillField = true
          }
        } else {
          this.submitEvents({ value })
        }
      } else if (!this.question.required && this.isOther) {
        value.length > 0 ? data = { value: value, isOther: true, text: text } : data = { skip: true }
        this.submitEvents(data)
      } else {
        value.length > 0 ? data = { value } : data = { skip: true }
        this.submitEvents(data)
      }
    },
    submitEvents (data) {
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
      this.$root.$emit('refresh-footer')
    }
  }
}
</script>
<style lang="scss">
.content-enum-multiple {
  width: 100%;
  min-height: 370px;
  box-sizing: border-box;
  padding: 2px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .options-enum-multiple {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .max-choice {
      width: 95%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .limit-choice {
        color: var(--buttonBg);
        font-size: 13px;
      }
    }
    .option, .option-other {
      background: transparent;
      cursor: pointer;
      min-height: 50px;
      width: 95%;
      border-radius: 5px;
      margin: 3px;
      padding: 5px;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        width: 94%;
        font-size: 16px;
        color: var(--splashColor);
      }
      .unchecked, .checked, .unchecked-other, .checked-other {
        color: var(--splashColor);
        font-size: 20px;
        margin-left: 3px;
      }
    }
    .option:hover {
      transform: scale(1.01);
    }
    .option-other {
      flex-direction: column;
      .content-other {
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 94%;
        }
      }
      .option-text {
        width: 95%;
        height: 50px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        input {
          font-size: 16px;
          width: 95%;
          height: 40px;
          margin-bottom: 5px;
          color: var(--splashColor);
          border-radius: 5px;
          outline: none;
          padding-left: 5px;
          border: 1px solid #e0e0e0;
        }
        .message-length-other {
          font-size: 12px;
          color: #ff0000d9;
        }
      }
    }
  }
}

// responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-enum-multiple {
    width: 99% !important;
    min-height: var(--body-height) !important;
    .options-enum-multiple {
      .option, .option-other {
        width: 98%;
        border-radius: 1px;
        padding: 5px 0;
        p {
          padding-left: 10px;
          font-size: 15px;
        }
      }
      .option-other {
        .option-text {
          width: 98%;
          input {
            width: 100%;
            border-radius: 1px;
          }
          .message-length-other {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
